.App {
  text-align: center;
  display: flex;
  flex-direction: row;
}
a.active{ 
  color: #009449;
}
@keyframes halfBounce2 {
  0%,
  100% {
      transform: scale(.9)
  }
  50% {
      transform: scale(1)
  }
}
@keyframes rotated {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
.FirstShap{

  animation: rotated 20s infinite linear;
}

.SecondShap{
  animation: halfBounce2 2s infinite;
}