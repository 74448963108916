body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

p, div, span, a {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-weight: 700;
}